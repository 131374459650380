body {
  background-color: white !important;
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  color: #585858;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rs-dropdown-menu {
  width: 110%;
  box-shadow: #b23437;
  z-index: 99 !important;
}

.rs-dropdown a svg {
  color: #b23437;
  padding-bottom: 2px;
  transform: scale(1.5);
}

.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  color: #b23437;
  stroke: #b23437;
  stroke-width: 2px;
}

.rs-picker-toggle:hover.rs-btn .rs-picker-toggle-caret {
  color: white !important;
  stroke: white !important;
}

.home-picker .rs-btn:hover .rs-picker-toggle-caret {
  color: #b23437 !important;
  stroke: #b23437 !important;
}

.rs-dropdown:hover a svg {
  color: white;
}

.buy-filterbar {
  text-align-last: center !important;
}

.buy-filterbar .rs-btn:hover {
  background-color: #b23437 !important;
  color: white !important;
}

.buy-filterbar .rs-btn:hover span {
  color: white !important;
}

.dropdown a:hover {
  background-color: #b23437 !important;
  color: white !important;
}

.rs-dropdown-item-focus {
  background-color: rgba(178, 52, 55, 0.1) !important;
  color: rgba(178, 52, 55) !important;
}

.rs-carousel-label-wrapper input:checked ~ label {
  color: #b23437 !important;
}

:root {
  --rs-carousel-indicator-active: #b23437 !important;
  --primary-color: #b23437 !important;
  --rs-slider-progress: #b23437 !important;
  --rs-slider-thumb-border: #b23437 !important;
  --rs-toggle-checked-bg: #b23437 !important;
  --rs-toggle-checked-hover-bg: #b23437 !important;
  --rs-radio-tile-checked-color: #b23437 !important;
  --rs-checkbox-checked-bg: #b23437 !important;
  --rs-input-focus-border: #b23437 !important;
  --rs-listbox-option-hover-bg: rgba(178, 52, 55, 0.1) !important;
  --rs-state-focus-shadow: #b23437 !important;
  --rs-input-focus-border: #b23437 !important;
  --rs-text-link: #b23437 !important;
  --rs-text-link-hover: #b23437 !important;
  --rs-color-focus-ring: rgba(178, 52, 55, 0.2) !important;
  --rs-sidenav-default-bg: white !important;
  --rs-btn-ghost-border: #b23437 !important;
  --rs-btn-ghost-text: #b23437 !important;
  --rs-btn-ghost-hover-border: #b23437 !important;
  --rs-btn-ghost-hover-text: #b23437 !important;
  --rs-text-primary: #1f1f21 !important;
  --rs-text-secondary: #575757 !important;
  --rs-text-tertiary: #8e8e93 !important;
  --rs-listbox-option-hover-text: #b23437 !important;
  --rs-picker-value: #1f1f21 !important;
  --rs-listbox-option-selected-bg: rgba(178, 52, 55, 0.1) !important;
  --rs-listbox-option-selected-text: #b23437 !important;
}

.rs-modal-dialog {
  margin-top: 50px;
  margin-bottom: 100px;
}

/* .rs-input-group input {
  max-width: min-content !important;
  min-width: 40px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  text-align: end;
}

.rs-input-group input::placeholder {
  color: #585859;
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  will-change: transform;
  animation: fadeIn 0.4s;
}

.fade-in2 {
  will-change: transform;
  animation: fadeIn 4s;
}

.rs-radio-tile-mark {
  display: none !important;
}

.rs-radio-tile-checked {
  background-color: #b23437;
}

.rs-radio-tile-checked > .rs-radio-tile-body > .rs-radio-tile-content {
  color: white;
}

.rs-modal-header span {
  transform: scale(1.5);
  margin-top: 10px;
  margin-right: 5px;
}

.rs-fullscreen-propertie-close {
  background: transparent;
  border: none;
  cursor: pointer;
  line-height: 1;
  outline: none !important;
  padding: 8px 8px 0;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  font-size: 30px !important;
  padding: 0;
  z-index: 100 !important;
  position: absolute;
  right: 15px !important;
  top: 25px !important;
}

@media (min-width: 768px) and (max-width: 1410px) {
  body {
    zoom: 0.8 !important;
  }
}

@media (min-width: 1410px) and (max-width: 1920px) {
  body {
    zoom: 1.1 !important;
  }
}

@media (min-width: 1920px) and (max-width: 2560) {
  body {
    zoom: 1.2 !important;
  }
}

@media (min-width: 2200px) {
  .image-home {
    background-position: 50% 76% !important;
  }
}

@media not all and (min-width: 768px) {
  .rs-modal-md {
    /* max-width: calc(100% - 50px) !important; */
    max-width: fit-content - 20px !important;
    height: max-content !important;
  }
  .rs-modal-body {
    max-height: 660px !important;
  }
  .rs-modal-dialog {
    margin-top: -19px !important;
  }
  .radio-tile-style-string {
    padding-bottom: 11px !important;
    padding-top: 11px !important;
    padding-left: 6px !important;
    padding-right: 13px !important;
    font-size: xx-small !important;
  }
  .radio-tile-style-number {
    padding-bottom: 11px !important;
    padding-top: 11px !important;
    padding-left: 3px !important;
    padding-right: 8px !important;
    font-size: xx-small !important;
  }
  .rs-checkbox-checker > label {
    font-size: small !important;
  }
  .rs-input-group-addon {
    padding: 2px 3px !important;
    max-height: min-content !important;
  }
  .rs-fullscreen-propertie-close {
    right: -50px !important;
    top: 110px !important;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu {
    width: auto;
    border-radius: 0.5rem !important;
    z-index: 99;
  }
  .rs-sidenav-nav {
    width: inherit;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .rs-sidenav-item {
    border-radius: 0.5rem !important;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    top: 8px !important;
  }
  .rs-modal-lg,
  .rs-modal-md {
    max-width: calc(100% - 10px) !important;
    width: inherit !important;
  }
  .image-home {
    background-image: url("./imgs/bg-home-mobile.webp") !important;
  }
}

.rs-modal-lg,
.rs-modal-md {
  max-width: 600px !important;
}

.carousel-inner {
  border-radius: 0.75rem;
  object-fit: cover;
  background-color: white;
}

.control-next {
  border-radius: 0 0.75rem 0.75rem 0;
}

.control-prev {
  border-radius: 0.75rem 0 0 0.75rem;
}

.carousel.carousel-slider .control-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 48% !important;
  background-color: #b23437 !important;
  max-height: fit-content;
  border-radius: 50px;
  margin: 0px 8px;
}

.carousel-exclusivos .carousel.carousel-slider {
  overflow: visible !important;
}

.carousel-exclusivos .carousel.carousel-slider .control-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 48% !important;
  transform: scale(1.8);
  background-color: #b23437 !important;
  max-height: fit-content;
  border-radius: 50px;
  margin-left: -28px;
  margin-right: -22px;
}

.carousel-exclusivos .carousel.carousel-slider .control-arrow:hover {
  background-color: #b23437 !important;
}
.carousel-exclusivos .carousel.carousel-slider .control-arrow.control-next {
  padding-left: 6px;
  margin-left: 2px;
}

.carousel-exclusivos .carousel.carousel-slider .control-arrow.control-prev {
  padding-right: 6px;
  margin-right: 2px;
}

.carousel-exclusivos
  .carousel.carousel-slider
  .control-arrow.control-prev::before {
  border-right: 8px solid white !important;
}

.carousel-exclusivos
  .carousel.carousel-slider
  .control-arrow.control-prev:hover {
  background-color: #b23437 !important;
}

.carousel-exclusivos
  .carousel.carousel-slider
  .control-arrow.control-next::before {
  border-left: 8px solid white !important;
}

.carousel-exclusivos
  .carousel.carousel-slider
  .control-arrow.control-next:hover {
  background-color: #b23437 !important;
}

.carousel-exclusivos-inner
  .carousel.carousel-slider
  .control-arrow.control-prev::before {
  border-right: 8px solid white !important;
}

.carousel-exclusivos-inner
  .carousel.carousel-slider
  .control-arrow.control-next::before {
  border-left: 8px solid white !important;
}

.carousel-exclusivos-inner .carousel.carousel-slider .control-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 48% !important;
  background-color: #585858 !important;
  max-height: fit-content;
  border-radius: 50px;
  margin: 0px 8px;
  transform: scale(1);
  z-index: 30 !important;
}

.carousel-exclusivos-inner
  .carousel.carousel-slider
  .control-arrow.control-prev:hover {
  background-color: #585858 !important;
}

.carousel-exclusivos-inner
  .carousel.carousel-slider
  .control-arrow.control-next:hover {
  background-color: #585858 !important;
}

/* CARROUSEL HOME */

.image-home {
  background-image: url("./imgs/bg-home.webp");
  background-size: cover;
  background-position: center;
  /* animation: sliderBackground 10s infinite; */
  /* background: url("./imgs/bg-home.webp") 0 0 no-repeat,
    url("./imgs/bg-home3.jpg") 600px 0 no-repeat;
  animation: slide-right 15s forwards 1s; */
}

.rs-carousel-toolbar {
  display: none !important;
}

/* @keyframes slide-right {
  0% {
    background-position: 0 0, 600px 0, 1200px 0, 1800px 0;
  }
  33%,
  33% {
    background-position: -600px 0, 0 0, 600px 0, 1200px 0;
  }
  85%,
  86% {
    background-position: -1200px 0, -600px 0, 0 0, 600px 0;
  }
} */

/* @keyframes sliderBackground {
  0% {
    background-image: url("./imgs/bg-home.webp");
  }

  50% {
    background-image: url("./imgs/bg-home2.webp");
  }
} */

.image-sea {
  min-height: 100%;
  background: url("./imgs/bg-sea.webp");
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px #00000051;
}

.image-about {
  min-height: 100%;
  background: url("./imgs/bg-quem-somos.webp");
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px #00000081;
}

.carousel-control-div .carousel.carousel-slider .control-arrow {
  background-color: transparent !important;
  padding: 21% 12%;
  min-height: 100%;
  margin-top: -335px;
  margin-left: -20px;
  margin-right: 0;
}

.carousel-control-div .carousel .control-prev.control-arrow:before {
  border: transparent;
}

.carousel-control-div .carousel .control-next.control-arrow:before {
  border: transparent;
}

/* .image-maps {
  background-image: url("./maps_placeholder.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
} */

/* .rs-input-group .rs-input-group-addon {
  order: 3 !important;
  padding-top: 6px !important;
  padding-left: 0px !important;
}
.rs-input-group .rs-input-group-addon:first-child {
  order: 1 !important;
  min-width: 26px !important;
  padding-left: 5px !important;
}
.rs-input-group .rs-input {
  order: 2 !important;
  background-color: #f7f7fa !important;
  border-radius: 0px !important;
}
.rs-input-group .rs-input-number-btn-group-vertical {
  order: 4 !important;
  border-radius: 0px 6px 6px 0px !important;
  overflow: hidden !important;
} */
.carousel .slide {
  background-color: transparent !important;
}
.dropdown-filterbar button {
  background-color: white !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dropdown-filterbar button svg {
  padding-top: 5px;
}

.rs-picker-tag
  .rs-picker-toggle.rs-btn
  ~ .rs-picker-tag-wrapper
  > div:nth-child(n + 3)::after {
  content: "...";
}

.rs-picker-tag
  .rs-picker-toggle.rs-btn
  ~ .rs-picker-tag-wrapper
  > div:nth-child(n + 3)
  > .rs-tag-text {
  display: none;
}

.rs-picker-tag
  .rs-picker-toggle.rs-btn
  ~ .rs-picker-tag-wrapper
  > div:nth-child(n + 4) {
  display: none;
}

/* animation-slide-right */

.slide {
  position: absolute;
  right: -100px;
  -webkit-animation: slide 0.7s forwards;
  animation: slide 0.7s forwards;
}

@-webkit-keyframes slide {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle,
.rs-sidenav-nav > .rs-sidenav-item {
  padding-left: 10px !important;
}

.rs-sidenav-collapse-in .rs-dropdown-item,
.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  padding: 15px 20px !important;
}

.active-filters {
  flex-flow: wrap;
}

.rs-modal-backdrop {
  height: 100% !important;
  width: 100% !important;
}

.propertie-modal-backdrop {
  height: 100% !important;
  width: 100% !important;
  background-color: black !important;
}

.modal-carousel .rs-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.modal-carousel {
  max-width: 95% !important;
}

.stepper-wrapper {
  /* margin-top: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1; */

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.active .step-counter {
  background-color: white;
  border: 2px solid #b23437;
}

.stepper-item.completed .step-counter {
  background-color: #b23437;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #b23437;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item.completed::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #b23437;
  width: 100%;
  top: 20px;
  right: 50%;
  z-index: 3;
}

.stepper-item.active::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #b23437;
  width: 100%;
  top: 20px;
  right: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.customInput {
  font-size: 90%;
  padding: 0.8em 0.8em;
  outline: none;
  border: 1px solid rgb(200, 200, 200);
  background-color: transparent;
  border-radius: 4px;
  width: 100%;
}

.customInput:focus {
  border: 1px solid #b23437;
}

.customInput::placeholder {
  padding-left: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

.inputSearchMobile {
  min-height: 50px;
  min-width: 190px;
  padding: 0 1rem;
  font-size: 12px;
  border: 1px solid #b23437;
  border-radius: 6px 0 0 6px;
  background-color: transparent;
}

.inputSearchMobile:focus {
  border-color: #b23437;
  outline: none;
}

.submitSearchMobile {
  min-height: 50px;
  padding: 0.5em 1em;
  border: none;
  border-radius: 0 6px 6px 0;
  background-color: #b23437 !important;
  color: #fff;
  font-size: 15px;
}

.rs-carousel-slider {
  transition-duration: 2s !important;
}
